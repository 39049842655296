<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-world"> </i> 通用管理 | 流程设计</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <el-button @click="edit" type="success">添加模板</el-button>
<!--          <el-button @click="edit" type="primary">编辑</el-button>-->
          <el-button @click="delMulti" type="danger">批量删除</el-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1"
                 @page-changed="getList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center"></el-table-column>
            <el-table-column prop="Id" label="id" width="100" align="center"/>
            <el-table-column prop="SchemeName" label="名称" width="200" align="center"/>
            <el-table-column prop="Description" label="描述" width="200" align="center"/>
            <el-table-column prop="NAME" label="状态" width="200" align="center"/>
            <el-table-column label="操作" align="center">
              <template slot-scope="{row}">
                <el-button @click="edit(row)" type="primary" size="mini">编辑</el-button>
                <el-button @click="del(row)" type="primary" size="mini">删除</el-button>
              </template>
            </el-table-column>
          </wgrid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Wgrid from "@/components/wgrid";
import Swal from "sweetalert2";
export default {
  name: "index",
  components: {Wgrid},
  data() {
    return {
      grid: {
        sea: {
          ['_pageSize']: 12,
          ['_curPage']: 0,
        },
        ls: [],
        total: 0,
        loading: false,
      },
      ids:''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getResult({
        url: "/api/api/scheme/page",
        data: self.grid.sea,
        completed: function ({ResultType, Message, AppendData}) {
          self.grid.loading = false
          if (ResultType == 0) {
            self.grid.ls = AppendData.Items
            self.grid.total = AppendData.Total
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    edit({Id}){
      this.$router.push({
        path:'/org/flow/edit',
        query:{id:Id}
      })
    },
    del({id}){
      let self = this;
      Swal.fire({
        text: "确定要删除吗？",
        icon: "question",
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        showCancelButton: true,
      }).then((v) => {
        if (v.isConfirmed) {
          self.whale.remote.getResult({
            url: "/api/scheme/delete",
            data: { id },
            completed: function () {
              self.whale.toast.info("删除成功")
              self.getList()
            }
          })
        }
      })
    },
    delMulti(){
      let self = this;
      let ids = self.ids
      if(!ids){
        self.whale.toast.info("请选择要删除的流程")
        return
      }
      Swal.fire({
        text: "确定要批量删除吗？",
        icon: "question",
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        showCancelButton: true,
      }).then((v) => {
        if (v.isConfirmed) {
          self.whale.remote.getResult({
            url: "/api/scheme/batchdelete",
            data: { ids },
            completed: function () {
              self.whale.toast.info("删除成功")
              self.getList()
            }
          })
        }
      })
    },
    handleSelectionChange(val) {
      let ids = val.map((value)=>value.Id)
      this.ids = ids.join(',')
    },
  }
}
</script>

<style scoped>

</style>